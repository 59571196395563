import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const optionTranslations = {
 es: {
   "Padre": "Padre",
   "Madre": "Madre", 
   "Tutor": "Tutor",
   "Jugador": "Jugador",
   "Hermano/a": "Hermano/a",
   "Masculino": "Masculino",
   "Femenino": "Femenino",
   "Portero": "Portero", 
   "Otro": "Otro",
   "Curso Intensivo": "Curso Intensivo",
   "Programa Profesional": "Programa Profesional", 
   "Curso Anual": "Curso Anual",
   "Programa Personalizado 24-25": "Programa Personalizado 24-25",
   "Programa Personalizado": "Programa Personalizado",
   "Tecnofútbol": "Tecnofutbol", 
   "Curso para Entrenadores": "Curso para Entrenadores",
   "Curso a Distancia": "Curso a Distancia",
   "Español": "Español",
   "Ingles": "Ingles",
   "Frances": "Frances", 
   "Árabe": "Árabe",
   "Holandés": "Holandés",
   "Recomendación": "Recomendación",
   "Redes Sociales": "Redes Sociales",
   "Ya he sido alumno de Marcet": "Ya he sido alumno de Marcet"
 },
 fr: {
   "Padre": "Père",
   "Madre": "Mère",
   "Tutor": "Tuteur", 
   "Jugador": "Joueur",
   "Hermano/a": "Frère/Sœur",
   "Masculino": "Masculin", 
   "Femenino": "Féminin",
   "Portero": "Gardien",
   "Otro": "Autre",
   "Curso Intensivo": "Stage intensif",
   "Programa Profesional": "Programme professionnel",
   "Curso Anual": "Stage annuel", 
   "Programa Personalizado 24-25": "Programme personnalisé 24-25",
   "Programa Personalizado": "Programme personnalisé",
   "Tecnofútbol": "Tecnofutbol",
   "Curso para Entrenadores": "Stage pour entraîneurs",
   "Curso a Distancia": "Stage à distance",
   "Español": "Espagnol",
   "Ingles": "Anglais",
   "Francés": "Français",
   "Árabe": "Arabe",
   "Holandés": "Néerlandais",
   "Recomendación": "Recommandation", 
   "Redes Sociales": "Réseaux sociaux",
   "Ya he sido alumno de Marcet": "J'ai déjà été élève de Marcet"
 },
 en: {
   "Padre": "Father",
   "Madre": "Mother",
   "Tutor": "Guardian",
   "Jugador": "Player",
   "Hermano/a": "Brother/Sister",
   "Masculino": "Male",
   "Femenino": "Female",
   "Portero": "Goalkeeper",
   "Otro": "Other",
   "Curso Intensivo": "Intensive Course",
   "Programa Profesional": "Professional Program",
   "Curso Anual": "Annual Course",
   "Programa Personalizado 24-25": "Personalized Program 24-25",
   "Programa Personalizado": "Personalized Program",
   "Tecnofútbol": "Tecnofutbol",
   "Curso para Entrenadores": "Coaches Course",
   "Curso a Distancia": "Distance Learning Course",
   "Español": "Spanish",
   "Ingles": "English",
   "Frances": "French",
   "Árabe": "Arabic", 
   "Holandés": "Dutch",
   "Recomendación": "Recommendation",
   "Redes Sociales": "Social Media",
   "Ya he sido alumno de Marcet": "I've been a Marcet student before"
 }
};

const resources = {
 es: {
   translation: {
     title: "¿Necesitas más información?",
     form: {
       relation: "Relación con el jugador",
       playerFirstName: "Nombre del jugador/a",
       playerLastName: "Apellido del jugador/a",
       secondLastName: "Segundo apellido",
       birthDate: "Fecha de Nacimiento", 
       gender: "Género",
       playerType: "Tipo de jugador",
       requesterFirstName: "Nombre del solicitante",
       requesterLastName: "Apellido del solicitante",
       secondRequesterLastName: "Segundo apellido solicitante",
       email: "Correo",
       phone: "Número de teléfono",
       areaCode: "Cód. de área",
       programInterest: "Interés en el programa",
       startDate: "Fecha de inicio preferido",
       preferredLanguage: "Idioma preferido",
       country: "País de origen",
       howFound: "¿Cómo nos conociste?"
     },
     buttons: {
       next: "Siguiente",
       back: "Atrás",
       submit: "Enviar"
     },
     errors: {
       required: "Este campo es requerido",
       invalidEmail: "Por favor ingresa un email válido", 
       selectOption: "Por favor selecciona una opción",
       selectDate: "Por favor selecciona una fecha",
       numbersOnly: "Solo se permiten números",
       selectCaptcha: "Por favor, completa el captcha"
     },
     success: "¡Formulario enviado con éxito!"
   }
 },
 fr: {
   translation: {
     title: "Besoin de plus d'informations?",
     form: {
       relation: "Relation avec le joueur",
       playerFirstName: "Prénom du joueur",
       playerLastName: "Nom du joueur", 
       secondLastName: "Deuxième nom du joueur",
       birthDate: "Date de naissance",
       gender: "Genre",
       playerType: "Type de joueur",
       requesterFirstName: "Prénom du demandeur",
       requesterLastName: "Nom du demandeur",
       secondRequesterLastName: "Deuxième nom du demandeur",
       email: "Email",
       phone: "Numéro de téléphone",
       areaCode: "Ind. reg",
       programInterest: "Intérêt pour le programme", 
       startDate: "Date de début souhaitée",
       preferredLanguage: "Langue préférée",
       country: "Pays d'origine",
       howFound: "Comment nous avez-vous connu?",
       selectCaptcha: "Veuillez compléter le CAPTCHA"
     },
     buttons: {
       next: "Suivant",
       back: "Retour",
       submit: "Soumettre"
     },
     errors: {
       required: "Ce champ est obligatoire",
       invalidEmail: "Veuillez saisir une adresse e-mail valide",
       selectOption: "Veuillez sélectionner une option",
       selectDate: "Veuillez sélectionner une date",
       numbersOnly: "Seuls les chiffres sont autorisés"
     },
     success: "Formulaire soumis avec succès !"
   }
 },
 en: {
   translation: {
     title: "Need more information?",
     form: {
       relation: "Relationship with player",
       playerFirstName: "Player's first name",
       playerLastName: "Player's last name",
       secondLastName: "Player's second last name",
       birthDate: "Date of birth",
       gender: "Gender", 
       playerType: "Player type",
       requesterFirstName: "Requester's first name",
       requesterLastName: "Requester's last name",
       secondRequesterLastName: "Requester's second last name",
       email: "Email",
       phone: "Phone number",
       areaCode: "Code",
       programInterest: "Program interest",
       startDate: "Preferred start date",
       preferredLanguage: "Preferred language",
       country: "Country of origin",
       howFound: "How did you find us?"
     },
     buttons: {
       next: "Next",
       back: "Back",
       submit: "Submit"
     },
     errors: {
       required: "This field is required",
       invalidEmail: "Please enter a valid email",
       selectOption: "Please select an option",
       selectDate: "Please select a date",
       numbersOnly: "Only numbers are allowed",
       selectCaptcha: "Please complete the CAPTCHA"
     },
     success: "Form submitted successfully!"
   }
 }
};

const getCurrentLanguage = () => {
  const path = window.location.host;
  console.log(path);
  if (path.includes('form-en')){ console.log("Inglés"); return 'en'; }
  if (path.includes('form-fr')) { console.log("Francés"); return 'fr'; }

  console.log("Espannol");
  return 'es';
 };

i18n
 .use(initReactI18next)
 .init({
   resources,
   lng: getCurrentLanguage(),
   fallbackLng: 'es',
   interpolation: {
     escapeValue: false
   }
 });

export default i18n;